<template>
  <div class="row">
    <div class="col-12 col-md-4">
      <form role="form">
        <div class="form-group row">
          <small
            v-show="errors.has('name')"
            class="row text-danger col-12 mx-0"
          >
            {{ $t("field_required") }}
          </small>
          <label class="col-sm-2 col-form-label">{{ $t("name") }}</label>
          <div class="col-sm-10">
            <input
              type="text"
              class="form-control"
              v-model="shortUrl.name"
              name="name"
            />
          </div>
        </div>
        <div class="form-group row">
          <small
            v-show="errors.has('redirect_url')"
            class="row text-danger col-12 mx-0"
          >
            {{ $t("field_required") }}
          </small>
          <label class="col-sm-2 col-form-label"
            >{{ $t("redirect_url") }} *</label
          >
          <div class="col-sm-10">
            <input
              type="text"
              class="form-control"
              v-model="shortUrl.redirect_url"
              v-validate="'required'"
              name="redirect_url"
            />
          </div>
        </div>
        <div class="form-group row">
          <small
            v-show="errors.has('slug')"
            class="row text-danger col-12 mx-0"
          >
            {{ $t("filed_length") + "6" }}
          </small>
          <label class="col-sm-2 col-form-label">{{ $t("slug") }}</label>
          <div class="col-sm-10">
            <input
              type="text"
              class="form-control"
              v-model="shortUrl.slug"
              name="slug"
              v-validate="'length:6'"
            />
          </div>
        </div>
        <div class="form-group row">
          <small
            v-show="errors.has('tracking')"
            class="row text-danger col-12 mx-0"
          >
            {{ $t("field_required") }}
          </small>
          <label class="col-sm-2 col-form-label">{{ $t("tracking") }}</label>
          <div class="col-sm-10">
            <input
              type="checkbox"
              class="form-control"
              v-model="shortUrl.tracking"
              name="tracking"
            />
          </div>
        </div>
      </form>
      <div class="row pt-4">
        <div class="col-12">
          <div
            @click="deleteShortUrl()"
            v-if="editModel"
            class="
              btn
              mx-sm-2 mx-md-0
              my-2
              btn-danger
              d-block d-md-inline-block
            "
          >
            Usuń
          </div>
          <div
            @click="handleSubmit()"
            class="
              btn
              mx-md-2
              my-2
              btn-primary
              d-block d-md-inline-block
              float-md-right
            "
          >
            Zapisz
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Form",
  data() {
    return {
      shortUrl: {
        redirect_url: "",
        name: "",
        slug: "",
        tracking: ""
      },
      editModel: false
    };
  },
  created() {
    if (this.$route.params.id !== undefined) {
      this.getData();
    } else {
      this.$emit("loading", false);
    }
  },
  methods: {
    handleSubmit() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit("loading", true);
          if (this.editModel === true) {
            this.updateShortUrl();
          } else {
            this.saveShortUrl();
          }
        }
      });
    },
    saveShortUrl() {
      this.$http
        .post("/short-redirects", this.shortUrl)
        .then(() => {
          this.$toastr.success("Zapisane !");
          this.$emit("loading", false);
          this.$router.push("/pl/neonail/short-redirects");
        })
        .catch(() => {
          this.$toastr.error("Błąd podczas zapisu !");
          this.$emit("loading", false);
        });
    },
    updateShortUrl() {
      const url = `/short-redirects/${this.$route.params.id}`;
      this.$http
        .put(url, this.shortUrl)
        .then(() => {
          this.$toastr.success("Zaktualizowano !");
          this.$emit("loading", false);
          this.$router.push("/pl/neonail/short-redirects");
        })
        .catch(() => {
          this.$toastr.error("Błąd podczas aktualizacji !");
          this.$emit("loading", false);
        });
    },
    deleteShortUrl() {
      this.$emit("loading", true);
      this.$http
        .delete(`/short-redirects/${this.$route.params.id}`)
        .then(() => {
          this.$toastr.success("Usunięte!!");
          this.$emit("loading", false);
          this.$router.push("/pl/neonail/short-redirects");
        })
        .catch(err => {
          this.$toastr.error(err);
        });
    },
    getData() {
      this.$emit("loading", true);
      let url = `/short-redirects/${this.$route.params.id}`;
      this.$http
        .get(url)
        .then(resp => {
          this.shortUrl = resp.data;
          this.editModel = true;
          this.$emit("loading", false);
        })
        .catch(err => {
          this.$toastr.error(err);
          this.$emit("loading", false);
        });
    }
  }
};
</script>
